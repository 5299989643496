import React, { FunctionComponent } from "react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { ImageInterface } from "../../types/SanityTypes";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import "./styles.scss";

export interface HeroImageInterface {
  image: ImageInterface;
}

const HeroImage: FunctionComponent<HeroImageInterface> = ({ image }) => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  return (
    <div data-testid="hero-image" className="hero-image">
      <picture
        className="placeholder"
        style={{
          ...(image.asset.metadata
            ? {
                paddingTop: `calc(50% / ${image.asset.metadata.dimensions.aspectRatio})`,
                paddingBottom: `calc(27% / ${image.asset.metadata.dimensions.aspectRatio})`,
                background: `url(${image.asset.metadata.lqip})`,
                backgroundSize: "cover"
              }
            : null)
        }}
      >
        <source
          media={"(min-width: 1200px)"}
          srcSet={
            urlBuilder.image(image).auto("format").quality(80).width(1400).height(520).format("webp").url() as string
          }
        />
        <source
          media={"(min-width: 992px)"}
          srcSet={
            urlBuilder.image(image).auto("format").quality(80).width(900).height(440).format("webp").url() as string
          }
        />
        <source
          media={"(min-width: 641px)"}
          srcSet={
            urlBuilder.image(image).auto("format").quality(80).width(600).height(440).format("webp").url() as string
          }
        />
        <img
          src={urlBuilder.image(image).auto("format").quality(80).width(540).height(400).format("webp").url() as string}
          alt={image.alt}
        />
      </picture>
    </div>
  );
};

export default HeroImage;
